.table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}

.table th {
    background-color: var(--primary-color);
    color: white;
    padding: 0.75rem;
    text-align: left;
    border-bottom: 2px solid #e5e7eb;
}

.table td {
    padding: 0.75rem;
    border-bottom: 1px solid #e5e7eb;
}

.table tr:nth-child(even) {
    background-color: #f9fafb;
}

.table tr:hover {
    background-color: #f3f4f6;
}

.graph-container {
    height: 300px;
    /* Limite la hauteur des graphiques */
    max-width: 100%;
    /* Assure que le graphique s'adapte au conteneur */
    margin: auto;
    /* Centrage */
}