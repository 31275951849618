.navbar {
    width: 250px;
    background-color: #2c3e50;
    /* Couleur de fond */
    color: white;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Enable vertical scrolling */
}

.navbar .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
    text-decoration: none;
}

.navbar-links {
    list-style: none;
    padding: 0;
}

.navbar-links>li {
    margin-bottom: 10px;
    cursor: pointer;
}

.navbar-links>li>span {
    color: white;
    font-size: 1rem;
    display: block;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.navbar-links>li>span:hover {
    background-color: #34495e;
}

.submenu {
    list-style: none;
    padding-left: 20px;
    margin-top: 5px;
}

.submenu li {
    margin-bottom: 5px;
}

.submenu li a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 0.9rem;
    padding: 5px 0;
    display: block;
    transition: color 0.3s;
}

.submenu li a:hover {
    color: #1abc9c;
}