/* Global CSS variables */
:root {
    --primary-color: #1d4ed8;
    /* Couleur principale */
    --secondary-color: #f43f5e;
    /* Couleur secondaire */
    --background-color: #f9fafb;
    /* Couleur de fond */
    --text-color: #111827;
    /* Couleur du texte */
    --font-family: 'Inter', sans-serif;
}

/* Reset des marges et paddings */
body {
    margin: 0;
    padding: 0;
    font-family: var(--font-family);
    background-color: var(--background-color);
    color: var(--text-color);
}

/* Liens */
a {
    text-decoration: none;
    color: var(--primary-color);
}

a:hover {
    color: var(--secondary-color);
}

/* Boutons */
button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
    transition: background-color 0.2s;
}

button:hover {
    background-color: var(--secondary-color);
}

.app-layout {
    display: grid;
    min-height: 100vh;
    background-color: #f4f4f4;
    justify-content: unset;
}

.navbar {
    width: 250px;
    height: 100vh;
    background-color: #2c3e50;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.content {
    margin-left: 250px;
    /* Décalage pour le menu */
    padding: 20px;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: grid;
    /* flex-grow: 1; */
    background-color: var(--background-color);;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        flex-grow: 1;
}


.footer {
    background-color: #2c3e50;
    color: white;
    text-align: center;
    padding: 10px;
    position: relative;
    bottom: 0;
    width: 100%;
}

main {
    flex-grow: 1;
    padding: 2rem;
    background-color: #f9fafb;
    /* Couleur d'arrière-plan */
}

footer {
    margin-top: auto;
}


/* Titres */
h1,
h2 {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--text-color);
}

/* Champs de recherche */
input[type="text"] {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    margin-right: 10px;
    width: 50%;
    font-size: 0.9rem;
}

input[type="text"]:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 5px rgba(29, 78, 216, 0.5);
}

/* Boutons */
button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s, transform 0.2s;
}

button:hover {
    background-color: var(--secondary-color);
    transform: translateY(-1px);
}

button:active {
    transform: translateY(1px);
}

button.primary {
    background-color: var(--primary-color);
    color: white;
}

button.danger {
    background-color: var(--secondary-color);
    color: white;
}

/* Tableaux */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

table thead {
    background-color: var(--primary-color);
    color: white;
    text-align: left;
}

table th,
table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

table tr:hover {
    background-color: #f2f2f2;
}

table th {
    font-weight: bold;
    text-transform: uppercase;
}

.table-actions button {
    margin-right: 5px;
}

/* Pagination */
.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.pagination button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    background-color: var(--primary-color);
    color: white;
}

.pagination span {
    font-size: 0.9rem;
    color: var(--text-color);
}

/* Formulaires */
form {
    margin-bottom: 20px;
}

form label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
}

form input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    margin-bottom: 10px;
}

form input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px rgba(29, 78, 216, 0.5);
}

/* Amélioration des espaces */
.table-container {
    margin-top: 20px;
}

.form-container {
    margin-bottom: 20px;
}

button+button {
    margin-left: 10px;
}